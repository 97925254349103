@font-face {
    font-family: 'Nunito';
    src: local('Nunito Regular'), local('Nunito-Regular'),
        url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Light'), local('Nunito-Light'),
        url('../fonts/Nunito-Light.woff2') format('woff2'),
        url('../fonts/Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
        url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Black'), local('Nunito-Black'),
        url('../fonts/Nunito-Black.woff2') format('woff2'),
        url('../fonts/Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
        url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
        url('../fonts/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Extra Light'), local('Nunito-ExtraLight'),
        url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
        url('../fonts/Nunito-ExtraBold.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Bold'), local('Nunito-Bold'),
        url('../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../fonts/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito Medium'), local('Nunito-Medium'),
        url('../fonts/Nunito-Medium.woff2') format('woff2'),
        url('../fonts/Nunito-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}
